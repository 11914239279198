import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import analyticMiddleware from '../middleware/analyticMiddleware'
import sharedReducer from '../reducers/sharedReducer'
import cartReducer from '../reducers/cartReducer'
import formReducer from '../reducers/formReducer'
import notificationReducer from '../reducers/notificationReducer'
import headerReducer from '../reducers/headerReducer'
import { errorReducer } from '../reducers/errorReducer'
import loginReducer from '../reducers/loginReducer'

const noResultStore = createStore(combineReducers({
    forms: formReducer,
    notification: notificationReducer,
    shared: sharedReducer,
    header: headerReducer,
    errors: errorReducer,
    cart: cartReducer,
    login: loginReducer
  }),
  compose(
    applyMiddleware(thunk, analyticMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
)
export default noResultStore
